<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
        <div class="card">
            <div class="card-header">
                <h5>
                    <i class="fa fa-calendar"></i>
                    الاجازات / العطلات
                    &nbsp;
                    <button class="btn btn-primary btn-sm" v-b-modal.add style="border-radius: 50%; width: 30px; height: 30px; padding:0px">
                        <i class="fa fa-plus"></i>
                    </button>
                </h5>
            </div>
            <div class="card-footer">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered">
                        <thead>
                            <th>
                                العنوان
                            </th>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                الموظفين
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="a in list" :key="a._id">
                                <td>
                                    {{ a.title }}
                                    <span class="btn btn-sm btn-primary" v-if="a.from_system" style="padding: 2px;padding-left: 5px"><i class="fa fa-check"></i> بواسطة النظام</span>
                                </td>
                                <td>
                                    {{ a.date }}
                                </td>
                                <td>
                                    <span v-if="a.teachers.includes('*')">الكل</span>
                                    <div v-if="!a.teachers.includes('*')">
                                        <ul>
                                            <li v-for="t in a.teachers" :key="t">
                                                {{ teachersname(t) }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-outline-danger" @click="deleteDay(a._id)" style="border-radius: 0px;padding: 5px">
                                        <i class="fa fa-trash"></i> حذف
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" title="اضافة اجازة" hide-footer>
        <div class="form-group">
          <label for="">اليوم</label>
          <input type="date"
            class="form-control" v-model="date">
        </div>
        <div class="form-group">
          <label for="">عنوان الاجازة</label>
          <input type="text"
            class="form-control" v-model="title" placeholder="اكتب هنا...">
          <small id="helpId" class="form-text text-muted">مثل: تعليق دراسة٫ عيد الاضحى الخ...</small>
        </div>
        <div class="g">
            اختر الموظفين (<strong class="text-danger">لا تختر احد اذا كنت تريد اختيار الكل</strong>)
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="teacher in teachers" :key="teacher._id">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_teachers" :value="teacher.number">
                {{ teacher.name }}
              </label>
            </div>
        </div>
        <div class="col-12 text-center g text-center">
            <button class="btn btn-primary" @click="addnow()">
                اضافة الاجازة
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            list: [],
            date: new Date().toISOString().split("T")[0],
            title: "",
            teachers: [],
            selected_teachers: []
        }
    },
    created(){
        if(!checkPer("teachers|settings")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getList();
        var g = this;
        $.post(api + "/user/teachers/list", {
            jwt: g.user.jwt,
        })
        .then(function (r) {
            g.teachers = r.response
        })
    },
    methods: {
        teachersname(t){
            var g = "";
            this.teachers.forEach(element => {
                if(element.number == t){
                    g = element.name;
                }
            });
            return g;
        },
        addnow(){
            var g = this;
            $.post(api + '/user/teachers/freedays/add', {
                jwt: this.user.jwt,
                date: this.date,
                title: this.title,
                teachers: this.selected_teachers
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getList()
                    $("#add___BV_modal_header_ > button").click()
                }
            }).fail(function(){
            
            })
        },
        getList(){
            var g = this;
            $.post(api + '/user/teachers/freedays/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.list = r.response
                }
            }).fail(function(){
            
            })
        },
        deleteDay(id){
            if(confirm("متأكد من حذف هذا اليوم من الاجازات؟\nقد يؤثر هذا الاجراء على التقارير السابقة.")){
                var g = this;
                $.post(api + '/user/teachers/freedays/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getList()
                    }
                }).fail(function(){
                
                })
            }
        }
    }
}
</script>

<style>

</style>